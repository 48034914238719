.App {
  background-color: #f9fbfc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Text {
  color: #06475a;
  font-family: 'Cormorant Garamont';
  font-size: 22px;
  text-align: center;
}
