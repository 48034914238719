.image-container {
    background-color: #f9fbfc;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 50%;
    height: auto;
}